import {
  HomeQueryParams,
  HomeQueryParamsKey,
  HomeRouteParamLiterals,
} from "./routes.js";

const { SEARCH } = HomeQueryParamsKey;

/** `HomeQueryParamParsers` maps allowed `HomeQueryParamKey`s to helper functions used
 * to parse query params to values to be consumed by our frontend components */
export const HomeQueryParamParsers = {
  [SEARCH]: (search: string): string => decodeURIComponent(search),
} as const;

/** Maps query param key/value pairs to the keys expected by our router (https://github.com/hex-inc/hex/blob/master/packages/client/route/routes.ts)
 * Note: this is particuarly important for `category` and `status` which, since our router expects
 * the keys `categoryNames` and `statusNames`, but our query param keys are `category` and `status` respectively.
 */
export const mapSearchParamsToHomeQueryParams = ({
  access,
  archived,
  category,
  collection,
  creator,
  direction,
  duration,
  favorited,
  owner,
  pendingReview,
  published,
  requiresReview,
  scheduled,
  search = "",
  sharedToWeb,
  sharedWithWorkspace,
  sort,
  status,
  ...otherNonHomeQueryParams
}: Record<HomeQueryParamsKey, string | undefined>) => {
  const searchParamsToHomeQueryParams: {
    [k in HomeRouteParamLiterals]: HomeQueryParams[k];
  } = {
    sort,
    duration,
    favorited,
    archived,
    search,
    sharedWithWorkspace,
    sharedToWeb,
    scheduled,
    direction,
    pendingReview,
    requiresReview,
    published,
    // params as lists that need to be converted to string arrays
    categoryNames: category != null ? category.split(",") : undefined,
    statusNames: status != null ? status.split(",") : undefined,
    owners: owner != null ? owner.split(",") : undefined,
    creators: creator != null ? creator.split(",") : undefined,
    collections: collection != null ? collection.split(",") : undefined,
    access: access != null ? access.split(",") : undefined,
  };
  return {
    searchParamsToHomeQueryParams,
    otherNonHomeQueryParams,
  };
};
